import React from 'react';

import OptionsStyled from './Options.styled';

const Options = ({
  token,
  setToken,
  siteId,
  setSiteId,
  accountId,
  setAccountId,
  env,
  setEnv,
}) => (
  <OptionsStyled>
    <div>
      <div className="formInput">
        <label>CP Token</label>
        <textarea
          type="token"
          name="p"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          rows="6"
          cols="50"
        />
      </div>

      <div className="formInput">
        <label>Account ID</label>
        <input
          type="accountId"
          name="p"
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
        />
      </div>

      <div className="formInput">
        <label>Site ID</label>
        <input
          type="siteId"
          name="p"
          value={siteId}
          onChange={(e) => setSiteId(e.target.value)}
        />
      </div>

      <div className="formInput">
        <label>Environment</label>
        <select
          type="env"
          name="p"
          value={env}
          onChange={(e) => setEnv(e.target.value)}
        >
          <option value="alpha">Alpha</option>
          <option value="prod">Production</option>
        </select>
      </div>
    </div>
  </OptionsStyled>
);

Options.displayName = 'Options';

export default Options;
