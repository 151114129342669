import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Options from './components/Options';
import SiteData from './components/SiteData';
import Preview from './components/Preview';
import CalendarData from './components/CalendarData';
import AppStyled from './App.styled';

function App() {
  // Required API data
  const [token, setToken] = useState('');
  const [accountId, setAccountId] = useState('');
  const [siteId, setSiteId] = useState('');
  const [env, setEnv] = useState('alpha');

  const [errorMsg, setErrorMsg] = useState('');

  // Data Storage
  const [siteData, setSiteData] = useState({});
  const [siteLoading, setSiteLoading] = useState(false);
  const [experianceData, setExperianceData] = useState({});
  const [experianceLoading, setExperianceLoading] = useState(false);
  const [calData, setCalData] = useState([]);
  const [calLoading, setCalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteLoaded, setSiteLoaded] = useState(false);

  const clearData = () => {
    setSiteData({});
    setCalData([]);
    setSiteLoaded(false);
    setErrorMsg('');
  };

  const getData = () => {
    const apiHost = `https://api.${env}-api.sardius.media`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    };

    setSiteLoading(true);
    setCalLoading(true);
    setSiteLoaded(true);
    setExperianceLoading(true);
    setErrorMsg('');

    const sitesApi = `${apiHost}/sites/${accountId}/${siteId}/`;
    fetch(sitesApi, { headers })
      .then(function (response) {
        console.log('site response', response);
        return response.json();
      })
      .then(function (jsonResponse) {
        const { data, Message } = jsonResponse;
        console.log('site data', jsonResponse);
        if (data) {
          setSiteData(data);
          setSiteLoading(false);
        } else {
          console.log(data);
          setErrorMsg(
            <div>
              {`Error Loading Site Data no data`}
              <br />
              {`Message: "${Message}`}
            </div>,
          );
          setSiteLoading(false);
        }
      });

    const experianceApi = `${apiHost}/sites/${accountId}/${siteId}/experience`;
    fetch(experianceApi, { headers })
      .then(function (response) {
        console.log('experiance response', response);
        return response.json();
      })
      .then(function (jsonResponse) {
        const { data, Message } = jsonResponse;
        console.log('experiance data', jsonResponse);
        if (data) {
          setExperianceData(data[0]);
          setExperianceLoading(false);
        } else {
          console.log(data);
          setErrorMsg(
            <div>
              {`Error Loading Experiance Data no data`}
              <br />
              {`Message: "${Message}`}
            </div>,
          );
          setExperianceLoading(false);
        }
      });

    const calendarApi = `${apiHost}/calendars/${accountId}/${siteId}/events?start=${moment().toISOString()}&end=${moment()
      .add(6, 'days')
      .toISOString()}`;
    fetch(calendarApi, { headers })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (jsonResponse) {
        const { message, Message } = jsonResponse;
        console.log('calendar data', jsonResponse, message);
        if (!message && !Message) {
          setCalData(jsonResponse);
          setCalLoading(false);
        } else {
          setErrorMsg(
            <div>
              {`Error Loading Calendar Data no data`}
              <br />
              {`Message: "${message || Message}`}
            </div>,
          );
          setCalLoading(false);
        }
      });
  };
  const updateEvent = (eventId, newEventData) => {
    console.log(eventId, newEventData);
    const apiHost = `https://api.${env}-api.sardius.media`;
    const updateEventApi = `${apiHost}/calendars/${accountId}/${siteId}/events/${eventId}`;
    setCalLoading(true);
    fetch(updateEventApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(newEventData),
    })
      .then((response) => response.json())
      .then(function (data) {
        console.log(data);
        // setCalData(data);
        setCalLoading(false);
      });
    forceLiveUpdate();
  };
  const forceLiveUpdate = (eventId, newEventData) => {
    console.log(eventId, newEventData);
    const apiHost = `https://api.${env}-api.sardius.media`;
    const forceLiveUpdateApi = `${apiHost}/sites/${accountId}/${siteId}/trigger?ver=${moment().valueOf()}`;
    setCalLoading(true);
    fetch(forceLiveUpdateApi, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then(function (data) {
        console.log(data);
        // setCalData(data);
        setCalLoading(false);
      });
  };

  useEffect(() => {
    setLoading(
      !siteLoading && !calLoading && !experianceLoading ? false : true,
    );
  }, [siteLoading, calLoading, experianceLoading]);

  return (
    <AppStyled className="App">
      {(!siteLoaded || errorMsg !== '') && (
        <div className="getInfo">
          <div>
            <div className="error">{errorMsg}</div>
            <Options
              token={token}
              setToken={setToken}
              siteId={siteId}
              setSiteId={setSiteId}
              accountId={accountId}
              setAccountId={setAccountId}
              env={env}
              setEnv={setEnv}
            />
            <button
              className="large"
              disabled={loading}
              onClick={() => {
                getData();
              }}
            >
              {loading ? 'Loading...' : 'Load Site'}
            </button>
          </div>
        </div>
      )}
      <div className="mainContent row">
        <div className="scrollable">
          <SiteData siteData={siteData} />
          <CalendarData
            calData={calData}
            setCalData={setCalData}
            updateEvent={updateEvent}
          />
        </div>

        <Preview siteKey={siteData.siteUrl || ''} env={env} />
      </div>
      <footer className="App-header">
        <button
          className="large secondary"
          disabled={loading}
          onClick={() => {
            clearData();
          }}
        >
          Exit Site
        </button>
        <div>
          <button
            className="large"
            disabled={loading}
            onClick={() => {
              getData();
            }}
          >
            {loading ? 'Loading...' : 'Reload Site'}
          </button>
        </div>
      </footer>
    </AppStyled>
  );
}

export default App;
