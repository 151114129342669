import styled from 'styled-components';

export default styled.div`
  .formInput {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  label {
    display: block;
    padding: 3px 0;
    font-size: 14px;
    font-weight: 900;
  }
  input,
  select,
  textarea {
    padding: 15px 15px;
    border: none;
    border-radius: 3px;
  }
`;
