import React, { useState, useEffect } from 'react';

import PreviewStyled from './Preview.styled';

const Preview = ({ siteKey, env }) => {
  const [iframeSource, setIframeSource] = useState('');
  useEffect(() => {
    if (env === 'prod') {
      setIframeSource(
        `https://${siteKey
          .replace('layout_', '')
          .toLocaleLowerCase()}.sardius.live/`,
      );
    } else {
      setIframeSource(
        `https://${siteKey
          .replace('layout_', '')
          .toLocaleLowerCase()}.sites.alpha.sardius.media/`,
      );
    }
  }, [siteKey]);

  return (
    siteKey && (
      <PreviewStyled>
        <iframe
          width="640"
          height="1136"
          title="SitePreview"
          src={iframeSource}
        />

        <small>
          <a href={iframeSource} rel="noreferrer" target="_blank">
            {iframeSource}
          </a>
        </small>
      </PreviewStyled>
    )
  );
};

Preview.displayName = 'Preview';

export default Preview;
