import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  .row {
    display: flex;
    flex-direction: row;
  }
  .App-header {
    background-color: #33384b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: calc(10px + 0.5vmin);
    color: white;
    padding: 5px 20px;
  }
  h2 {
    margin: 10px 0;
  }
  button.large {
    background-color: #2f6ab9; /* Green */
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    border-radius: 3px;
    &.secondary {
      background-color: #515669;
    }
  }
  .scrollable {
    height: 100%;
    overflow-x: scroll;
  }
  .mainContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: auto;
  }
  .getInfo {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dddfe5;
    div {
      background: #f1f2f5;
      padding: 20px;
      border-radius: 10px;
    }
  }
`;
