import React from 'react';
import moment from 'moment';
import CalendarDataStyled from './CalendarData.styled';

const CalendarData = ({ calData = [], setCalData, updateEvent }) => {
  const updateActiveCard = (eventIndex, cardIndex) => {
    const newCalData = [...calData];
    newCalData[
      eventIndex
    ].settings.experiences.access_default.secondScreenLive.map(
      (updateCard, updateCardIndex) => {
        updateCard.currentlyActive =
          updateCardIndex === cardIndex ? true : false;
        return updateCard;
      },
    );
    setCalData(newCalData);

    const newEventData = { ...newCalData[eventIndex] };
    const newEventId = newEventData.id;
    delete newEventData.id;
    delete newEventData.createdBy;
    delete newEventData.updatedBy;
    delete newEventData.createdAt;
    delete newEventData.updatedAt;
    updateEvent(newEventId, newEventData);
  };
  return (
    <CalendarDataStyled>
      {calData.length <= 0 ? (
        <h4 className="warning">No Upcoming Events</h4>
      ) : (
        <div>
          {calData.map((event, eventIndex) => {
            const secondScreenCards =
              event.settings?.experiences?.access_default?.secondScreenLive ||
              [];
            return (
              <div className="event" key={eventIndex}>
                <h3>
                  {event.title}
                  <br />
                  <small>
                    {moment(event.start).format('lll')}-
                    {moment(event.end).format('lll')}
                  </small>
                </h3>
                {secondScreenCards.length <= 0 ? (
                  <h4 className="warning">
                    No Second Screen <br />
                    Cards for "{event.title}"
                  </h4>
                ) : (
                  secondScreenCards.map((card, cardIndex) => (
                    <div
                      key={cardIndex}
                      className={card.currentlyActive ? 'card active' : 'card'}
                      onClick={() => {
                        updateActiveCard(eventIndex, cardIndex);
                      }}
                    >
                      <div className="index">{cardIndex}</div>
                      <div className="meta">
                        <div className="name">
                          {card?.cardName}
                          <div className={`tag`}>{card.type}</div>
                        </div>
                        <div className="title">
                          <strong>Title: </strong>
                          {card?.title}
                        </div>
                        <div className="subtitle">
                          {' '}
                          <strong>Sub Title: </strong>
                          {card?.subtitle}
                        </div>
                        <div className="description">
                          {' '}
                          <strong>Description: </strong>
                          {card?.description}
                        </div>
                      </div>
                      <div className={`tag live`}>
                        {card.currentlyActive ? 'Live' : 'Inactive'}
                      </div>
                    </div>
                  ))
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* <div className="code">
        <pre>{JSON.stringify(calData, ' ', 2)}</pre>
      </div> */}
    </CalendarDataStyled>
  );
};

CalendarData.displayName = 'CalendarData';

export default CalendarData;
