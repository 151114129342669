import styled from 'styled-components';

export default styled.div`
  padding: 0 10px;
  .event {
    padding-bottom: 20px;
    border-bottom: 1px solid #c9cbd6;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 2px;
    padding: 5px 15px;
    border-radius: 10px;
    min-height: 23px;
    .index {
      min-width: 30px;
      padding-top: 2px;
      align-self: flex-start;
    }
    .meta {
      width: 100%;
      .name {
        font-weight: bold;
      }
      .title,
      .subtitle,
      .description {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &:hover {
      background-color: #c9cbd6;
      button {
        opacity: 1;
      }
    }
  }
  button {
    width: 80px;
    padding: 3px;
    margin: 3px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .tag {
    display: inline-block;
    border: 1px solid #8cb0e2;
    background-color: #f2f6fc;
    color: #2f6ab9;
    font-size: 10px;
    padding: 3px 7px;
    margin: 3px 3px 3px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    &.live {
      display: none;
    }
  }
  .active {
    &.card {
      background-color: #dddfe5;
      font-weight: bold;
    }
    .tag.live {
      display: inline-block;
      background-color: #dc3705;
      border-color: #be3004;
      font-weight: 900;
      color: #fff;
      font-size: 13px;
      margin: 3px;
    }
  }
  small {
    font-weight: 100;
    font-size: 13px;
  }
  .warning {
    text-align: center;
    background-color: #fff8f2;
    padding: 20px;
    border-radius: 10px;
    color: #c95700;
    border: 1px solid #ff9f56;
  }
`;
