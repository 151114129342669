import styled from 'styled-components';

export default styled.div`
  padding: 0 10px;
  h2 {
    margin-bottom: 0;
  }
  small {
    font-weight: 100;
    font-size: 13px;
  }
`;
