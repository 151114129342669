import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  iframe {
    border: 0;
    box-shadow: 0 2px 4px rgba(10, 10, 10, 0.15),
      0 2px 4px rgba(10, 10, 10, 0.15);
    border-radius: 10px;
    margin: 10px 0;
  }
`;
