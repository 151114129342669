import React from 'react';

import SiteDataStyled from './SiteData.styled';

const SiteData = ({ siteData }) => (
  <SiteDataStyled>
    {siteData.pk && (
      <div>
        <div>
          <h2>{siteData.name}</h2>
          <small>
            <strong>Name: </strong>
            {siteData.name}
          </small>
          <br />
          <small>
            <strong>Template Name: </strong>
            {siteData.description}
          </small>
        </div>
      </div>
    )}
  </SiteDataStyled>
);

SiteData.displayName = 'SiteData';

export default SiteData;
